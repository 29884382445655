<template>
  <div>
    <xModal
      title="转让超管"
      :visible="visible"
      :w="510"
      :footer="null"
      @handleCancel="handleCancel"
    >
      <template #content>
        <Form
          ref="formRef"
          :model="formState"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :rules="rules"
          class="form-box"
        >
          <!-- 转出至 -->
          <FormItem label="转出至" name="target_user_id">
            <a-select
              v-model:value="formState.target_user_id"
              show-search
              placeholder="请选择"
              style="width:320px;"
              :filter-option="filterOption"
              @change="handleUserChange"
            >
              <a-select-option
                v-for="item in userList"
                :value="item.id"
                :label="item.name"
                :key="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 本账号角色 -->
          <FormItem label="本账号角色" name="current_user_new_role_ids">
            <a-select
              v-model:value="formState.current_user_new_role_ids"
              mode="multiple"
              placeholder="请选择（可多选）"
              option-label-prop="label"
              style="width:320px;"
            >
              <a-select-option
                v-for="item in roleList"
                :value="item.id"
                :label="item.name"
                :key="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </FormItem>
          <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
            <a-button @click="handleCancel">取消</a-button>
            <a-button
              class="ml-16"
              type="primary"
              @click="handleSubmit"
              :loading="isLoading"
              >确定</a-button
            >
          </FormItem>
        </Form>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { Form, message } from "ant-design-vue";
import { getInvoiceRules } from "./config";
import xModal from "@/components/xModal";
import $await from "@/utils/await";
import {
  useUserListApi,
  useRoleListApi,
  useTransferSuperRoleApi
} from "@/apis/system";

export default {
  props: {
    visible: { type: Boolean, default: false }
  },
  components: {
    xModal,
    Form,
    FormItem: Form.Item
  },
  setup(props, ctx) {
    const store = useStore();
    const userListApi = useUserListApi();
    const roleListApi = useRoleListApi();
    const transferSuperRoleApi = useTransferSuperRoleApi();

    const isLoading = ref(false);
    const formRef = ref();

    // 用户列表
    const userList = ref([]);
    // 角色列表
    const roleList = ref([]);

    // 表单信息
    const formState = ref({
      target_user_id: undefined,
      current_user_new_role_ids: []
    });

    const rules = getInvoiceRules(formState);

    // 获取用户列表
    (async () => {
      const [, res] = await $await(userListApi());
      // 筛选调本账号
      res.forEach(item => {
        if (item.id === store.getters.userInfo.id) {
          res.splice(res.indexOf(item), 1);
        }
      });
      userList.value = res;

      const [, role] = await $await(roleListApi());
      roleList.value = role;
    })();

    // 提交表单
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        const [, res] = await $await(transferSuperRoleApi(formState.value));
        if (res) {
          isLoading.value = false;
          message.success("转让成功！");
          handleCancel();
          location.href = location.origin;
        } else {
          isLoading.value = false;
        }
      });
    };

    // 更换字典分类
    const handleUserChange = val => {
      formState.value.target_user_id = val;
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    return {
      isLoading,
      formRef,
      userList,
      roleList,
      formState,
      handleCancel,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      handleSubmit,
      handleUserChange,
      filterOption,
      rules
    };
  }
};
</script>

<style lang="less" scoped>
.input-grey {
  width: 320px;
  height: 32px;
  background: #f5f5f5;
  border-radius: 4px;
  padding-left: 8px;
  line-height: 32px;
}
</style>
<style lang="less"></style>
