// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    role_ids: [
      {
        required: true,
        type: "array",
        trigger: "blur",
        message: "本账号角色不能为空"
      }
    ]
  };
  return rules;
};
