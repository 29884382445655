<template>
  <div class="page-main-box">
    <Button
      v-if="$store.getters.roles.includes('SUPER')"
      class="px-24 ml-24 mt-24 fz-12"
      type="primary"
      @click="transferModal = true"
      >转让超管</Button
    >
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          id="service-order-table"
          :dataSource="tableDataSource"
          height="auto"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column field="username" min-width="180" title="账号" />
          <p-t-column field="name" min-width="149" title="姓名" />
          <p-t-column field="roleNames" min-width="311" title="角色">
            <template v-slot="{ row }">
              <span> {{ row.roleNames.join() }}</span>
            </template>
          </p-t-column>
          <p-t-column field="create_time" min-width="176" title="首次登陆时间">
            <template v-slot="{ row }">
              <span>{{
                formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
              }}</span>
            </template>
          </p-t-column>
          <p-t-column width="60" title="操作" fixed="right">
            <template v-slot="{ row }">
              <Button type="link" @click="handleAmend(row)">
                修改
              </Button>
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>

    <!-- 转让超管弹窗 -->
    <TransferAuthority
      v-if="transferModal"
      :visible="transferModal"
      @cancel="transferModal = false"
    >
    </TransferAuthority>

    <!-- 修改角色弹窗 -->
    <AmendAuthority
      v-if="amendModal"
      :visible="amendModal"
      :dataInfo="dataInfo"
      @getData="reflesh"
      @cancel="amendModal = false"
    >
    </AmendAuthority>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { Button } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { useUserPageApi } from "@/apis/system";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import useTableHeight from "@/hooks/useTableHeight";
import TransferAuthority from "./components/TransferAuthority/index";
import AmendAuthority from "./components/AmendAuthority/index";

export default {
  name: "service-contract",
  components: { Button, TransferAuthority, AmendAuthority },
  setup() {
    const userPageApi = useUserPageApi();

    // table高度
    const tableHeight = ref();

    const transferModal = ref(false);
    const amendModal = ref(false);
    const dataInfo = ref({});

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const reflesh = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo,
          exclude_role_code: "SUPER"
        };
        const [, res] = await $await(userPageApi(data));

        res.list.map(i => {
          i.roleNames = [];
          i.roleIds = [];
          i.roles.map(j => {
            i.roleNames.push(j.name);
            i.roleIds.push(j.id);
          });
        });
        return useTableDataRecords(res);
      }
    });

    // 修改权限
    const handleAmend = row => {
      dataInfo.value = row;
      amendModal.value = true;
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      transferModal,
      amendModal,
      tableDataSource,
      formatToDateTime,
      dataInfo,
      handleAmend,
      reflesh
    };
  }
};
</script>

<style lang="less" scoped>
#serviceContractBox {
  position: relative;
  overflow: hidden;
  .contract-name {
    color: #007aff;
    cursor: pointer;
  }
}
.content-detail {
  display: flow-root;
}
</style>
