<template>
  <div>
    <xModal
      title="修改角色"
      :visible="visible"
      :w="510"
      :footer="null"
      @handleCancel="handleCancel"
    >
      <template #content>
        <Form
          ref="formRef"
          :model="formState"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :rules="rules"
          class="form-box"
        >
          <!-- 账号 -->
          <FormItem label="账号">
            <p class="input-grey">{{ dataInfo.username }}</p>
          </FormItem>

          <!-- 账号 -->
          <FormItem label="姓名">
            <p class="input-grey">{{ dataInfo.name }}</p>
          </FormItem>

          <!-- 本账号角色 -->
          <FormItem label="角色" name="role_ids">
            <a-select
              v-model:value="formState.role_ids"
              mode="multiple"
              placeholder="请选择（可多选）"
              option-label-prop="label"
              style="width:320px;"
            >
              <a-select-option
                v-for="item in roleList"
                :value="item.id"
                :label="item.name"
                :key="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </FormItem>
          <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
            <a-button @click="handleCancel">取消</a-button>
            <a-button
              class="ml-16"
              type="primary"
              @click="handleSubmit"
              :loading="isLoading"
              >确定</a-button
            >
          </FormItem>
        </Form>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { Form, message } from "ant-design-vue";
import { getInvoiceRules } from "./config";
import xModal from "@/components/xModal";
import $await from "@/utils/await";
import { useRoleListApi, useGrantRoleApi } from "@/apis/system";

export default {
  props: {
    visible: { type: Boolean, default: false },
    dataInfo: { type: Object, default: () => {} }
  },
  components: {
    xModal,
    Form,
    FormItem: Form.Item
  },
  setup(props, ctx) {
    const store = useStore();

    const roleListApi = useRoleListApi();
    const grantRoleApi = useGrantRoleApi();

    const isLoading = ref(false);
    const formRef = ref();

    // 角色列表
    const roleList = ref([]);

    // 表单信息
    const formState = ref({
      user_id: props.dataInfo.id,
      role_ids: props.dataInfo.roleIds
    });

    const rules = getInvoiceRules(formState);

    // 获取用户列表
    (async () => {
      const [, role] = await $await(roleListApi());
      roleList.value = role;
    })();

    // 提交表单
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        const [, res] = await $await(grantRoleApi(formState.value));
        if (res) {
          isLoading.value = false;
          message.success("修改成功！");

          // 如果修改的是当前账号权限，则刷新页面
          if (formState.value.user_id === store.getters.userInfo.id) {
            location.href = location.origin;
            return;
          }
          // 更新列表
          ctx.emit("getData");
          // 关闭弹窗
          handleCancel();
        } else {
          isLoading.value = false;
        }
      });
    };

    // 更换字典分类
    const handleUserChange = val => {
      formState.value.target_user_id = val;
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    return {
      isLoading,
      formRef,
      roleList,
      formState,
      handleCancel,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      handleSubmit,
      handleUserChange,
      filterOption,
      rules
    };
  }
};
</script>

<style lang="less" scoped>
.input-grey {
  width: 320px;
  height: 32px;
  background: #f5f5f5;
  border-radius: 4px;
  padding-left: 8px;
  line-height: 32px;
}
</style>
<style lang="less"></style>
