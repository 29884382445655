// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    target_user_id: [
      {
        required: true,
        trigger: "blur",
        message: "转出用户不能为空"
      }
    ],
    current_user_new_role_ids: [
      {
        required: true,
        type: "array",
        trigger: "blur",
        message: "本账号角色不能为空"
      }
    ]
  };
  return rules;
};
